import {
  Routes,
  Route
} from "react-router-dom";
import Privacy from './components/Privacy';
import TermsOfUse from './components/TermsOfUse';
import Home from "./Home";
import NavBar from './components/NavBar';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<TermsOfUse />} />
      </Routes>
      <NavBar />
    </div>
  );
}
// https://www.youtube.com/watch?v=KAG5wNPdoTw&ab_channel=GreatStack

export default App;
