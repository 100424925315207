import styles from './styles.module.css'


const FeatureSlide = ({img_source, title_text, paragraph_text, is_rtl}) => {
    const getStyles = () => {
        let styles_list = styles.slide
        if (is_rtl) styles_list += ` ${styles.rtl_slide}`
        else styles_list += ` ${styles.ltr_slide}`

        return styles_list 
    }

    return (
        <div className={getStyles()}>
            <div className={styles.text_container}>
                <p className={styles.title}>{title_text}</p>
                <p className={styles.paragraph}>{paragraph_text}</p>
            </div>
            <img className={styles.photo} src={img_source}/>
        </div>
    );
};

export default FeatureSlide;