import styles from './styles.module.css'
const CommercialButton = ({ image_source, small_text, large_text, link, inverted }) => {
    const openLink = () => window.open(link)
    return (
        <div className={`${styles.button_container} ${inverted ? (styles.inverted_container) : (styles.default_container)}`} onClick={openLink}>
            <img className={styles.image} src={image_source}></img>
            <div className={styles.text_container}>
                <p className={`${styles.small_txt} ${inverted ? (styles.inverted_text) : (styles.default_text)}`}>{small_text}</p>
                <p className={`${styles.large_txt} ${inverted ? (styles.inverted_text) : (styles.default_text)}`}>{large_text}</p>
            </div>
        </div>
    )
}
CommercialButton.defaultProps = { inverted: false };

export default CommercialButton;