import styles from './styles.module.css'

const IconCircle = ({image_source, link}) => {
    const openLink = () => window.open(link)

    return (
        <div className={styles.circle_container} onClick={openLink}>
            <img className={styles.icon} src={image_source} />
        </div>
    );
}

export default IconCircle;