import kidPhoto from './images/kidWithPhone.jpg';
import kidPhotoMobile from './images/kidWithPhoneMobile.jpg';
import styles from './styles.module.css'
import BasicButton from '../BasicButton';
import BackgroundImage from '../BackgroundImage';

const Section1 = () => {
    const isMobile = window.innerWidth <= 600;
    const background_image_source = isMobile ? (kidPhotoMobile) : (kidPhoto)
    return (
        <>
            <BackgroundImage src={background_image_source} />

            <div className={`${styles.hebrew_text} ${styles.on_image}`}>
                <p className={styles.p_title}>
                    מהיום אף אחד לא<br />
                    נשאר מאחור בחומר
                </p>
                <p className={styles.p_txt}>
                    שיעורי מתמטיקה ברמת מורה פרטי, עשירים ומהנים לכל גיל. {isMobile ? (<></>): (<br />)}
                    GoMath מורכבת ממערך פדגוגי המכיל שיעורים מוקלטים {isMobile ? (<></>): (<br />)}
                    ופרטניים, תרגול לפי רמות ומבחנים תחרותיים מהנים.
                </p>
                <BasicButton text={"התחילו עכשיו"} style={styles.basic_button} />
            </div>
        </>)
}
export default Section1