import styles from './styles.module.css'
import CommercialButton from '../CommercialButton';
import appleLogo from './images/appleLogoWhite.svg'
import googlePlayLogo from './images/googlePlayLogoWhite.svg'


const Section7Mobile = () => {
    const commercial_buttons = [
        {
            image_source: appleLogo,
            small_text: "Download on the",
            large_text: "App Store",
            link: "http://www.google.com"
        },
        {
            image_source: googlePlayLogo,
            small_text: "Get it on",
            large_text: "Google play",
            link: "http://www.google.com",
        }
    ]
    return (
        <div className={styles.section_contianer}>
            <h1 className={styles.first_text}>עם GoMath</h1>
            <h1 className={styles.second_text}>אף אחד לא נשאר מאחור בחומר</h1>
            <h1 className={styles.third_text}>הורידו מאחת החנויות והתחילו בשגרת לימודים נעימה ומהנה</h1>
            <div className={styles.commercial_buttons}>
                {commercial_buttons.map((com_button) => {
                    return <CommercialButton
                        image_source={com_button.image_source}
                        small_text={com_button.small_text}
                        large_text={com_button.large_text}
                        link={com_button.link} 
                        inverted={true} />
                        
                })}
            </div>
        </div>
    );
}

export default Section7Mobile; 