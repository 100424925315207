import barcode from './images/barcode.svg'
import styles from './styles.module.css'
const Section7 = () => {
    return (
        <div className={styles.section_contianer}>
            <h1 className={styles.first_text}>עם GoMath אף אחד לא נשאר מאחור בחומר</h1>
            <h1 className={styles.second_text}>סרקו את הברקוד והתחילו בשגרת לימודים נעימה ומהנה</h1>
            <img className={styles.barcode} src={barcode} />
        </div>
    );
}

export default Section7; 