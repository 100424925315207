import styles from './styles.module.css'
import whiteLogo from './images/whiteLogo.svg'
import CommercialButton from '../CommercialButton';
import appleLogo from './images/applelogo.svg'
import googlePlayLogo from './images/googleplaylogo.svg'
import IconCircle from '../IconCircle';
import mailIcon from './images/mailicon.svg'
import facebookIcon from './images/facebookicon.svg'
import instagramIcon from './images/instagramicon.svg'
import { isMobile } from '../../Home';
import { Link } from "react-router-dom";

const Section8 = () => {
    const icon_circles = [
        { image_source: mailIcon, link: "http://www.google.com" },
        { image_source: facebookIcon, link: "https://www.facebook.com/profile.php?id=61556547143252" },
        { image_source: instagramIcon, link: "https://www.instagram.com/gomath_app" },
    ]
    const commercial_buttons = [
        {
            image_source: appleLogo,
            small_text: "Download on the",
            large_text: "App Store",
            link: "https://apps.apple.com/us/app/id1533830942"
        },
        {
            image_source: googlePlayLogo,
            small_text: "Get it on",
            large_text: "Google play",
            link: "https://play.google.com/store/apps/details?id=com.math.learnmath"
        }
    ]

    return (<>
        <div className={styles.top_container}>
            <div className={styles.top_left_container}>
                <img src={whiteLogo} />
            </div>
            {isMobile() ? (<></>) : (
                <div className={styles.top_mid_container}>
                    <h1 className={styles.mid_title}>חפשו בחנויות GoMath</h1>
                    <div style={{ display: 'flex', gap: '15px' }}>
                        {commercial_buttons.map((com_button) => {
                            return <CommercialButton
                                image_source={com_button.image_source}
                                small_text={com_button.small_text}
                                large_text={com_button.large_text}
                                link={com_button.link} />
                        })}
                    </div>
                </div>)}
            <div className={styles.top_right_container}>
                {icon_circles.map((icon_circle) => {
                    return <IconCircle
                        image_source={icon_circle.image_source}
                        link={icon_circle.link} />
                })}
            </div>
        </div>
        <div className={styles.bottom_container}>
            <Link to="/privacy"><p className={styles.copyrights}>Privacy</p></Link>
            <p className={styles.copyrights}>ⓒ2023 GoMath. All rights reserved</p>
            <Link to="/terms"><p className={styles.copyrights}>Terms of Use</p></Link>
        </div>
    </>
    )
}
export default Section8;