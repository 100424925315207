import styles from './App.module.css';
import section3background from './images/section-3-background.svg'
import section3lessons from './images/section-3-lessons.svg'


import Section1 from './components/Section1';
import Section2 from './components/Section2';
import Section3 from './components/Section3';
import Section4 from './components/Section4';
import Section5 from './components/Section5';
import Section6 from './components/Section6';
import NavBar from './components/NavBar';
import Section7 from './components/Section7';
import Section8 from './components/Section8';
import Section7Mobile from './components/Section7Mobile';


// 1. Components should be grouped in a folder. Put everything relevant in it. You can work with index.js and styles.module.css everywhere.
// 2. You should also put .svg and .png files inside the component folder if it is relevant. You can put it in assets folder.
// 3. App should be clean and minimal. Consider taking out some components "just" to make the App cleaner.
// 4. Texts should be in a texts file. Sometimes you need to "prepare the land" for adding another language to the website. Use i18n.
// 5. Make components more generic by getting ...props in the props. Doing so will make the component be more generic and used better.

export const isMobile = () => { return window.innerWidth <= 600 };
const isDesktop = () => {return window.innerWidth <= 1700}
function Home() {
  return (
    <div className="Home">
      <Section1 />
      <Section2 />
      <Section3 />
      {isDesktop() ? (<></>) : (<Section4 />)}
      <Section5 />
      <Section6 />
      {isMobile() ? (<Section7Mobile />) : (<Section7 />)}
      <Section8 />
    </div>
  );
}
// https://www.youtube.com/watch?v=KAG5wNPdoTw&ab_channel=GreatStack

export default Home;
