import styles from './styles.module.css'


const TopicBox = ({first_text, second_text, img_source}) => {
    let image_style = "";
    if (second_text) {image_style = styles.normal_image}
    else {image_style = styles.larger_image}
    return (
        <div className={styles.box}>
            <img className={image_style} src={img_source}/>
            <div className={styles.text_container}>
                <p className={styles.first_text}>{first_text}</p>
                {second_text ? (<p className={styles.second_text}>{second_text}</p>) : (<></>)}
            </div>
        </div>
    );
}; 
export default TopicBox;