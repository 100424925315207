import styles from './styles.module.css'
import smallBlueStar from './images/smallbluestar.svg'

const RecomandationBox = ({ stars_amount, recommendation_text, author }) => {
    let stars = [];
    for (var i = 0; i < stars_amount; i++) {
        stars.push(<img src={smallBlueStar} />);
    }
    return (<div className={styles.container}>
        <div style={{ height: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
            <div className={styles.stars_line}>{stars}</div>
            <div style={{ height: '60%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <p className={`${styles.recommendation_text} ${styles.hebrew_text}`}>{recommendation_text}</p>
            </div>
            <p className={`${styles.author} ${styles.hebrew_text}`}>{author}</p>
        </div>
    </div>)
}
export default RecomandationBox;