import TopicBox from '../../components/TopicBox'
import styles from './styles.module.css'
import calculator from './images/calculator2.svg'
import libra from './images/libra2.svg'
import fractions from './images/fractions2.svg'
import vectors from './images/vectors2.svg'

const Section2 = () => {
    const topic_boxes_section_2 = [
        { first_text: "ביטויים ופונקציות", image: vectors },
        { first_text: "אחוזים, עשרוני ושברים", image: fractions },
        { first_text: "בעיות מילוליות", image: libra },
        { first_text: "חיבור, חיסור, כפל וחילוק", image: calculator },
    ];

    return (<div className={styles.topics_line}>
        {
            topic_boxes_section_2.map((topic_box) => (
                <TopicBox img_source={topic_box.image} first_text={topic_box.first_text} />
            ))
        }
    </div>
    )
}
export default Section2