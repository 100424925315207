import BackgroundImage from '../BackgroundImage';
import feedbackBackground from './images/feedbackbackground.svg'
import feedbackBackgroundMobile from './images/feedbackbackgroundMobile.svg'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import styles from './styles.module.css'
import RecomandationBox from '../RecomendationBox';
import redHeart from './images/redheart.svg'
import rightArrow from './images/rightarrow.svg'
import leftArrow from './images/leftarrow.svg'
import BasicButton from '../BasicButton'
import { isMobile } from '../../Home'

const Section6 = () => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 1
        }
    };

    const recommendation_list = [
        {
            stars_amount: 5,
            recommendation_text:
                `אפליקציה ממש טובה בזכותה הבנתי שמתמטיקה יכולה להיות ממש מהנה, *ממליצה*”`,
            author: "Oria Rotgold"
        },
        {
            stars_amount: 5,
            recommendation_text:
                `וואו אפליקציה מצוינת!! מסיבירים שם יותר טוב מהמורה שלי😉 אז תודה וממליצה ממש❤️`,
            author: "נועה"
        },
        {
            stars_amount: 5,
            recommendation_text:
                `האפליקציה עוזרת לי ללמוד יותר טוב וקיבלתי את הציון הכי גבוה בכיתה שלי. תודה😊😊`,
            author: "דורון גולדנברג"
        },
        {
            stars_amount: 5,
            recommendation_text:
                `אפליקציה ידידותית, נוחה ואפקטיבית לשימוש. פשוט נהנה`,
            author: "אדי ארונוב"
        },
        {
            stars_amount: 5,
            recommendation_text:
                `אפליקציה טובה ממליץ בחום עליה תהיה לכם השתפרות מדהימה ביכולת מתמטית לי אישית היא מאוד עזרה`,
            author: "browl ggb"
        },
        {
            stars_amount: 5,
            recommendation_text:
                `האפליקציה מאוד נוחה! יש בה את כל מה שצריך כדי ללמוד! ממליצה בחוםםם!`,
            author: "הילה ויגוארי"
        }
        ,
        {
            stars_amount: 5,
            recommendation_text:
                `משחק מהמם ממש כיף ממליצה❤️`,
            author: "שוהם שרביט"
        }
    ]
    // const isMobile = () => {return window.innerWidth <= 600;}
    const background_image_source = isMobile() ? (feedbackBackgroundMobile) : (feedbackBackground)
    const CustomRightArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        return <button onClick={() => onClick()} style={{ backgroundColor: "rgba(125, 170, 255, 0.5)", border: "0", height: "34px", width: "34px", padding: "2px 0 0 3px", position: 'absolute', cursor: 'pointer', borderRadius: "50%", marginTop: "auto", right: "0.2%"}}><img src={rightArrow} /></button>;
    };
    const CustomLeftArrow = ({ onClick, ...rest }) => {
        const {
            onMove,
            carouselState: { currentSlide, deviceType }
        } = rest;
        return <button onClick={() => onClick()} style={{ backgroundColor: "rgba(125, 170, 255, 0.5)", border: "0", height: "34px", width: "34px", padding: "2px 0 0 1px", position: 'absolute', cursor: 'pointer', borderRadius: "50%", marginTop: "auto", left: "0.2%" }}><img src={leftArrow} /></button>;
    };
    
    return (<>
        <div style={{ position: "relative", marginTop: '4%' }}>
            <div style={{ position: "absolute", width: "100%" }}>
                <BackgroundImage src={background_image_source} />
            </div>
            <div className={styles.text_container}>
                <h1 className={`${styles.big_title} ${styles.hebrew_text}`}>התלמידים וההורים מספרים</h1>
                <p className={`${styles.lil_title} ${styles.hebrew_text}`}>זה תמיד מרגש אותנו <img src={redHeart} /></p>
            </div>
            <div className={styles.carousle_container} >
                <Carousel autoPlay customLeftArrow={<CustomLeftArrow />} customRightArrow={<CustomRightArrow />} autoPlaySpeed={10000} renderButtonGroupOutside infinite responsive={responsive} >
                    {recommendation_list.map((recommendation) => (
                        <RecomandationBox
                            stars_amount={recommendation.stars_amount}
                            recommendation_text={recommendation.recommendation_text}
                            author={recommendation.author} />
                    ))}
                </Carousel>
            </div>
        </div>
        <div className={styles.button_container}>
            <BasicButton style={styles.basic_button} text={"התחילו עכשיו"} />
        </div>
    </>)
}
export default Section6;