import BasicButton from "../BasicButton";
import navBarCode from './images/navbarcode.svg'
import blueLogo from './images/blueLogo.svg'
import styles from './style.module.css'
import { isMobile } from '../../Home';
import { Link } from "react-router-dom";

const NavBar = () => {
    return (<div className={styles.bar_stuff}>
        <div className={styles.left_side_bar}>
            <BasicButton text={"התחילו עכשיו"} style={styles.nav_bar_button} />
            {isMobile() ? <></> : <img src={navBarCode} />}
        </div>
        <Link to="/">
            <img className={styles.logo} src={blueLogo} />
        </Link>
    </div>)
}

export default NavBar;