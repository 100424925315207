const BasicButton = ({ text, style, ...props }) => {
  function openLink() {
    window.open('https://gomath.page.link/mathapp');
  }

  return (
    <button onClick={openLink} className={style} {...props}>
      {/* <img src={hoveredButtonBackround} /> */}
      {text}
    </button>
  );
};


export default BasicButton;
