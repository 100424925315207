import styles from './styles.module.css'
import book from './images/book.svg'
import people from './images/people.svg'
import star from './images/star.svg'
import playSign from './images/playsign.svg'
import TopicBox from '../TopicBox'

const Section5 = () => {
    const section5_style = { display: "block", margin: "5px", direction: "rtl" }
    const topic_boxes_section_5 = [
        { first_text: "מדורג 4.6", second_text: "כוכבים בממוצע", image: star },
        { first_text: "מעל 100 אלף", second_text: "תלמידים", image: people },
        { first_text: "מעל 30", second_text: "נושאים שונים", image: book },
        { first_text: "מעל 50", second_text: "שיעורים מוקלטים", image: playSign },
    ];
    return (
        <div className={styles.topics_line}>
            {
                topic_boxes_section_5.map((topic_box) => (
                    <TopicBox img_source={topic_box.image} first_text={topic_box.first_text} second_text={topic_box.second_text} />
                ))
            }
        </div>
    )
}
export default Section5