import styles from './styles.module.css'
import FeatureSlide from '../FeatureSlide';
import BasicButton from '../BasicButton';
import videoLessons from './images/3-Video-Lessons-GIF.gif'
import wordwide from './images/2-Worldwide-GIF.gif'
import trophy from './images/3-Trophy-GIF.gif'
import learningCurve from './images/4-Learning-curve-GIF.gif'

const Section3 = () => {
    const feature_slides = [
        {
            img_source: videoLessons, title_text: "הנגשת ידע איכותי לכל רמה",
            paragraph_text:
                `שיעורי מתמטיקה באיכות וידיאו וסאונד מעולים, פירוק נושאים מורכבים, דוגמאות בעזרת תרגילים ודרכי פיתרון מלאים. זמינים לצפייה פעם אחר פעם.`, is_rtl: false
        },
        {
            img_source: wordwide, title_text: "שגרת לימודים מכל מקום בעולם", paragraph_text:
                `שיעורים אונליין, בכל השפות הפופולריות, בזמן שהכי נוח לכם ולכמה זמן שתצטרכו, בין אם זה תרגול שגרתי יומי או חד פעמי שבועי.`, is_rtl: true
        },
        {
            img_source: trophy, title_text: "אתגרים יומיים ופרסים", paragraph_text:
                `משחק שגם מלמד וגם משאיר טעם לעוד? קיבלתם. אתגרים יומיים ומשימות, ניקוד ושלבים, פרסים ומדליות. ככה נשמרת מוטיבציה גבוהה, הערך העצמי גבוה שגרת לימודים עקבית ומהנה.`, is_rtl: false
        },
        {
            img_source: learningCurve, title_text: "מעקב אחר עקומת למידה", paragraph_text:
                `אנחנו יודעים כמה חשוב לכם לעקוב אחר ההתקדמות שלכם או של יקיריכם, המעקב שיצרנו עבורכם עובד בצורה יחסית ומשווה בין המבחנים.`, is_rtl: true
        },
    ];
    return (
        <div>
            <div className={styles.container_title_features_slides}>
                <p className={`${styles.title_features_slides} ${styles.hebrew_text}`}>למה GoMath?</p>
            </div>
            <div>
                {
                    feature_slides.map((slide) => {
                        return <FeatureSlide
                            img_source={slide.img_source}
                            title_text={slide.title_text}
                            paragraph_text={slide.paragraph_text}
                            is_rtl={slide.is_rtl} />
                    })
                }
            </div>
            <div className={styles.button_features_slides}>
                <BasicButton text={"התחילו עכשיו"} style={styles.basic_button} />
            </div>
        </div>)
}
export default Section3