import { useEffect, useRef, useState } from 'react'

import BasicButton from "../BasicButton"
import lessons_img from './images/section-3-lessons.svg'
import full_answers_img from './images/section-3-fullanswers.svg'
import competition_img from './images/section-3-competition.svg'
import selectedBar1 from './images/barselected1.svg'
import selectedBar2 from './images/barselected2.svg'
import selectedBar3 from './images/barselected3.svg'
import bookSelected from './images/bookselected.png'
import homeSelected from './images/homeselected.png'
import testSelected from './images/testselected.png'
import book from './images/book.svg'
import home from './images/home.svg'
import test from './images/test.svg'

import styles from './styles.module.css'



const slidesData = [
    {
        paragraph: <>בסיס ופרקטיקה על ידי צפייה בסרטון הדרכה. <br />בעזרת ההנחיה לומדים לפתור תרגילים מסוגים שונים לפי נושאים,<br />עם דוגמאות ובשלבים יד ביד עד לפיתרון.</>,
        title: <>מערך שיעורים ברמת מורה פרטי</>,
        imageSrc: lessons_img,
        scrollThresholdStart: 0,
        scrollThresholdFinish: 199,
        containerStyle: `${styles.text_container} ${styles.first_text_container}`,
        containerStyleDown: ``,
        containerStyleUp: `${styles.first_text_container_up}`,
        barImg: selectedBar1,
        smallImgSelected: bookSelected,
        smallImgStyle: styles.small_img1
    },
    {
        paragraph: <>כמות אינסופית של תרגילים מותאמים לפי רמת קושי נבחרת,<br />כשלכל תרגיל תשובה מפורטת הכוללת את תהליך החישוב<br />מההתחלה עד לפיתרון המלא.</>,
        title: <>תרגול מהנה ופתרונות מלאים</>,
        imageSrc: full_answers_img,
        scrollThresholdStart: 200,
        scrollThresholdFinish: 399,
        containerStyle: `${styles.text_container} ${styles.second_text_container}`,
        containerStyleDown: `${styles.second_text_container_down}`,
        containerStyleUp: `${styles.second_text_container_up}`,
        barImg: selectedBar2,
        smallImgSelected: homeSelected,
        smallImgStyle: styles.small_img2
    },
    {
        paragraph: <>כמות אינסופית של מבחנים לכל רמות הקושי, שמאפשרים מעקב<br />אחרי מגמת למידה. את המבחנים ניתן לעשות בקצב אישי או<br />בתחרות מול תלמיד אחר בזמן קצוב - אקסטרה אקשן ואדרנלין.</>,
        title: <>מבחנים אישיים ותחרותיים</>,
        imageSrc: competition_img,
        scrollThresholdStart: 400,
        scrollThresholdFinish: 599,
        containerStyle: `${styles.text_container} ${styles.third_text_container} `,
        containerStyleDown: `${styles.third_text_container_down}`,
        containerStyleUp: ``,
        barImg: selectedBar3,
        smallImgSelected: testSelected,
        smallImgStyle: styles.small_img3
    }
]

const scrollStatuses = {
    before: 0,
    middle: 1,
    after: 2
}

const scrollDirections = {
    down: 0,
    up: 1
}

const Section4 = () => {
    const imgRef = useRef();
    const [selectedData, setSelectedData] = useState(slidesData[0]);
    const [lastPosition, setLastPosition] = useState(window.scrollY);
    const [lastSelectedData, setLastSelectedData] = useState(window.scrollY);
    const [scrollStatus, setScrollStatus] = useState(scrollStatuses.before)
    const [containerStyle, setContainerStyle] = useState(`${styles.text_container} ${styles.first_text_container}`);
    const [scrollDirection, setScrollDircetion] = useState(scrollDirections.down);
    useEffect(() => {
        window.addEventListener("scroll", handleScroll, { passive: true });

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [scrollStatus, selectedData, scrollDirection, lastPosition]);

    const handleScroll = () => {
        // Determine if user scrolls down
        if (window.scrollY > lastPosition && lastSelectedData != selectedData) {
            setScrollDircetion(scrollDirections.down)
        }
        if (window.scrollY < lastPosition && lastSelectedData != selectedData) {
            setScrollDircetion(scrollDirections.up)
        }
        setLastPosition(window.scrollY)
        // Determine which data will be shown
        slidesData.forEach(function setSelectedDataByThreshold(slideData) {
            if ((window.scrollY - imgRef.current.offsetTop) > slideData.scrollThresholdStart && (window.scrollY - imgRef.current.offsetTop) < slideData.scrollThresholdFinish ) {
                if (lastSelectedData != slideData) setLastSelectedData(selectedData)
                setSelectedData(slideData);
            }
        })
        // Determine in what position the section will work
        if (window.scrollY < imgRef.current.offsetTop) { setScrollStatus(scrollStatuses.before); }
        else{setScrollStatus(scrollStatuses.middle)}
        if ((window.scrollY - imgRef.current.offsetTop) > slidesData[slidesData.length - 1].scrollThresholdFinish) setScrollStatus(scrollStatuses.after)
        // Determine what animation will be shown
        setContainerStyle(scrollDirection == scrollDirections.down ? `${selectedData.containerStyle} ${selectedData.containerStyleDown}` : `${selectedData.containerStyle} ${selectedData.containerStyleUp}`);

    };

    const getImageStyles = () => {
        let imageStyles = `${styles.background_img_container}`
        if (scrollStatus === scrollStatuses.middle) imageStyles += ` ${styles.background_img_container_fixed}`
        if (scrollStatus === scrollStatuses.after) imageStyles += ` ${styles.background_img_container_end}`

        return imageStyles;
    }

    return (
        <div>
            <div ref={imgRef}>
                <div className={getImageStyles()}></div>
            </div>
            <div className={`${styles.container_on_img} ${getImageStyles()}`}>
                <img className={styles.large_img} src={selectedData.imageSrc} />
                <div className={containerStyle} >
                    <p className={styles.title}>{selectedData.title}</p>
                    <p className={styles.paragraph}>{selectedData.paragraph}</p>
                    <BasicButton text={"התחילו עכשיו"} style={styles.basic_button} />
                </div>
                <img className={styles.bar_img} src={selectedData.barImg}/>
                <img className={styles.small_img1} src={book}/>
                <img className={styles.small_img2} src={home}/>
                <img className={styles.small_img3} src={test}/>
                <img className={selectedData.smallImgStyle} src={selectedData.smallImgSelected}/>
            </div>
        </div>
    );
}
export default Section4;